import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Text, Heading, TextInput, Button, MaskedInput } from 'grommet';
import * as Yup from 'yup';

import Loading from '../Loading';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required Field'),
  number: Yup.string().required('Required Field'),
});

const FormOrderLookup = ({ onValues, error }) => {
  return (
    <Box width={{ max: 'large' }}>
      <Formik
        validateOnMount={true}
        initialValues={{
          email: '',
          number: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          onValues(values);
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
          isSubmitting,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Box gap="medium">
                <Box>
                  <Heading margin="none" level={5} htmlFor="email">
                    Email
                  </Heading>
                  <TextInput
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus={true}
                  />
                </Box>
                <Box>
                  <Heading margin="none" level={5} htmlFor="number">
                    Order Number
                  </Heading>
                  <MaskedInput
                    name="number"
                    mask={[{ fixed: '#' }, { regexp: /^[0-9]*$/ }]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Box>
                {error && <Text color="status-error">{error.description}</Text>}
                <Button
                  primary
                  type="submit"
                  label="Submit"
                  disabled={isSubmitting || !isValid}
                  reverse
                  icon={isSubmitting ? <Loading /> : <div />}
                  onClick={handleSubmit}
                />
              </Box>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};

FormOrderLookup.propTypes = {
  onValues: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default FormOrderLookup;
